//import RepoIcon from "../img/dash.png";

import Icon from "@mui/material/Icon";
import React from "react";

export function RepoIconImg(props) {
    return (
        <Icon sx={{ height:50, width:50 }}>
            <img alt={props.altText} src={process.env.REACT_APP_BASE_URL + props.imageUri} height={50} width={50} />
        </Icon>
    );
}

export default RepoIconImg;