import React, {useState} from "react";
// import FormGroup from '@mui/material/FormGroup';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';
import { Autocomplete } from "@mui/material";
import TextField from "@mui/material/TextField";
import {useParams} from "react-router-dom";

function ManagementFacetSelector({onSelect, value, defaultValue, label, className, ...props}) {

    const params = useParams();

    const [inputValue, setInputValue] = React.useState('');
    //const [value, setValue] = React.useState([]);
    const [open, setOpen] = React.useState(false);
    const [state, setState] = useState({items: [], DataisLoaded: false});
    const [typedSearch, setTypedSearch] = useState("");

    React.useEffect( () => {
        // console.log(props);
        // console.log(defaultValue);
        // TODO: use props to select proper URL request
        var requestUrl = process.env.REACT_APP_API_BASE_URL + props.facet;
        // if (this.props.facet == "branches") requestUrl += "/branches";

        fetch(requestUrl)
            .then((res) => res.json())
            .then((json) => {
                setState({
                    items: json,
                    DataisLoaded: true
                });
            })
    }, []);

    function setNewInput(event, newInputValue, reason) {
        console.log('set input');
        console.log(reason);
        if (reason !== "reset" && reason !== "clear") {
            setTypedSearch(newInputValue.valueOf());
            setInputValue(newInputValue);
        } else {
            setInputValue(typedSearch);
        }
    }

    function closeAutocomplete(event, reason) {
        setInputValue("");
        setOpen(false)
    }
    //
    // function onChange(e, handler) {
    //     setValue(e.target.value); props.handleAddToFilters();
    // }
/*    function handleAffiliationChange(e, data) {
        console.log('affiliation changed');
        console.log(data);
        setValue(data);
        //onChange(data);
    }*/

/*    function handleChange(_e, obj) {
        if (obj === null) {
            setValue(obj);
            onSelect(null);
        } else {
            setValue(obj);
            onSelect(obj);
        }
    }*/

    if(!state.DataisLoaded) {
        return(<div><b>Loading...</b></div>);
    } else {
        return (
            <Autocomplete
                className={className}
                multiple
                disabled={!state.DataisLoaded}
                filterSelectedOptions
                disableCloseOnSelect
                options={state.items || []}
                defaultValue={defaultValue}
                getOptionLabel={(option) => option.name }
                isOptionEqualToValue={(option, value) => option.id === value.id}
                //onChange={handleChange}
                onChange={onSelect}
                onInputChange={(event, newInputValue, reason) => {
                    setNewInput(event, newInputValue, reason);
                }}
                onClose={() => {setOpen(false); setTypedSearch(""); setInputValue("");}}
                //value={value}
                //inputValue={inputValue}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="outlined" />
                )} />
        )
    }
}

export default ManagementFacetSelector;