import ExternalLinkImg from "../img/Bumpout Icon.png"
import Icon from "@mui/material/Icon";
import React from "react";

import rstLogo from "../img/rst-logo.png";
import rstLogoFooter from "../img/rst-logo-footer.png";

export function ExternalLinkIcon(props) {
    return (
        <Icon>
            <img alt='external link' src={ExternalLinkImg} height={20} width={20}/>
        </Icon>
    );
}

export function GrayMoneyIcon(props) {
    return (
        <Icon>
            <img alt='gray dollar sign icon' src={process.env.REACT_APP_BASE_URL + 'Gray Dollar Sign.png'} />
        </Icon>
    );
}
export function GrayIdIcon(props) {
    return (
        <Icon>
            <img alt='gray persistent id icon' src={process.env.REACT_APP_BASE_URL + 'Gray ID.png'} />
        </Icon>
    );
}
export function GrayIndividualIcon() {
    return (
        <Icon>
            <img alt='gray individual icon' src={process.env.REACT_APP_BASE_URL + 'Gray Individual.png'} />
        </Icon>
    );
}
export function GrayLockIcon() {
    return (
        <Icon>
            <img alt='gray lock icon' src={process.env.REACT_APP_BASE_URL + 'Gray Lock.png'} />
        </Icon>
    );
}
export function GrayMetadataIcon() {
    return (
        <Icon>
            <img alt='gray metadata icon' src={process.env.REACT_APP_BASE_URL + 'Gray Metadata.png'} />
        </Icon>
    );
}
export function GrayDataVolumeIcon() {
    return (
        <Icon>
            <img alt='gray data volume icon' src={process.env.REACT_APP_BASE_URL + 'Gray Data Volume.png'} />
        </Icon>
    );
}

export function GreenMoneyIcon(props) {
    return (
        <Icon>
            <img alt='green dollar sign icon' src={process.env.REACT_APP_BASE_URL + 'Green Dollar Sign.png'} />
        </Icon>
    );
}
export function GreenIdIcon() {
    return (
        <Icon>
            <img alt='green persistent id icon' src={process.env.REACT_APP_BASE_URL + 'Green ID.png'} />
        </Icon>
    );
}
export function GreenIndividualIcon() {
    return (
        <Icon>
            <img alt='green individual icon' src={process.env.REACT_APP_BASE_URL + 'Green Individual.png'} />
        </Icon>
    );
}
export function GreenLockIcon() {
    return (
        <Icon>
            <img alt='green lock icon' src={process.env.REACT_APP_BASE_URL + 'Green Lock.png'} />
        </Icon>
    );
}
export function GreenMetadataIcon() {
    return (
        <Icon>
            <img alt='green metadata icon' src={process.env.REACT_APP_BASE_URL + 'Green Metadata.png'} />
        </Icon>
    );
}
export function GreenDataVolumeIcon() {
    return (
        <Icon>
            <img alt='green data volume icon' src={process.env.REACT_APP_BASE_URL + 'Green Data Volume.png'} />
        </Icon>
    );
}

export function RSTLogo() {
    return (
        <img alt='Data Repository Finder logo' src={rstLogo} />
    )
}

export function RSTFooterLogo() {
    return (
        <img alt='Data Repository Finder logo for footer' src={rstLogoFooter} height='65px' />
    )
}
