import React from "react";
// import Grid from "@mui/material/Unstable_Grid2";
import RepositoryCard from "./RepositoryCard";
// import {Container} from "@mui/material";

const RepositoryGrid = (props) => {
    //const [state, setState] = useState({DataisLoaded: false, items: []});

    // React.useEffect( () => {
    //     var requestUrl = process.env.REACT_APP_API_BASE_URL + "repositories";
    //     requestUrl += props.queryParams;
    //     // console.log("using params");
    //     // console.log(requestUrl);
    //
    //     fetch(requestUrl)
    //         .then(response => response.json())
    //         .then((responseJson) => {
    //             setState({
    //                 DataisLoaded: true,
    //                 items: responseJson['repositories']
    //             });
    //             // console.log("state");
    //             // console.log(state);
    //         })
    // }, [props.queryParams]);

    // const fetchRepos = () => {
    //     var requestUrl = process.env.REACT_APP_API_BASE_URL + "repositories";
    //
    //     if(props.queryParams) {
    //         // console.log(props.queryParams);
    //         requestUrl += props.queryParams;
    //     }
    //
    //     fetch(requestUrl)
    //         .then(response => response.json())
    //         .then((responseJson) => {
    //             setState({
    //                 DataisLoaded: true,
    //                 items: responseJson['repositories']
    //             });
    //             // console.log("state");
    //             // console.log(state);
    //         })
    // };
    if (props.searchResultsLoaded) {
        if(props.searchResults.length > 0) {
            return (
                props.searchResults.map((item) => (
                    <div key={'repository-card-div-' + item.id} style={{padding: 5}}>
                        <RepositoryCard repository={item} compareIds={props.compareIds} handleAddToCompare={props.handleAddToCompare} />
                    </div>
                ))
            );
        } else {
            return (
                <div className='empty-list'>No search results returned.</div>
            )
        }
    } else {
        return (
            <div className='empty-list'>Loading search results...</div>
        );
    }
}

export default RepositoryGrid;