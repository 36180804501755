import React from "react";
import QuickView from "../quick_view/QuickView";

const QuickGlance = (props) => {
    // console.log(props);

    return (
        <React.Fragment>
            <h3>Quick Glance</h3>
            <QuickView repository={props.repository}></QuickView>
        </React.Fragment>
    );

}

export default QuickGlance;