import { useState } from 'react';

export default function useAuthString() {
    const getAuthString = () => {
        const tokenString = sessionStorage.getItem('flask');
        const userToken = tokenString;
        return userToken;
    };

    const [authString, setAuthString] = useState(getAuthString());

    const saveAuthString = userToken => {
        sessionStorage.setItem('flask', userToken);
        setAuthString(userToken);
    };

    const clearAuthString = () => {
        sessionStorage.removeItem('flask');
        setAuthString(null);
        console.log("User has been logged out.");
        console.log(sessionStorage.getItem('flask'));
    }

    return {
        clearAuthString,
        setAuthString: saveAuthString,
        authString
    }
}